.signature-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.signature-list {
    padding: 20px;
    padding-top: 0;
    flex: 1;
}

.signature-list h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.signature-list select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

.signature-list button {
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.signature-list button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.signature-preview {
    margin-top: 20px;
}

.signature-preview img {
    background: #fff;
    height: 200px;
    width: 323.6px;
}

.signature-pad {
    padding: 20px;
    padding-top: 0;
    flex: 1;
    border-left: 1px solid #ccc;
}

.signature-pad h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.signature-canvas {
    background-color: #fff;
    position: relative;
}

.signature-canvas::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-bottom: 2px solid #010101;
    transform: translateY(-50%);
}

.checkbox-button {
    padding: 0;
    width: 1.5em;
    height: 1.5em;
    font-size: 1em;
    border: 1px solid rgb(0, 49, 111);
    background-color: white;
    vertical-align: middle; /* Center vertically */
  }
  
  .selected-button {
    background-color: rgb(0, 49, 111);
    color: white;
  }

  .button-group-signature button {
    width: 100%;
    margin-left: 0;
  }