.search-container {
    margin-top: 50px;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
    min-height: 90vh;
}

.search-container a {
    color: #194d85de;
    text-decoration: none;
}

.search-container a:hover {
    text-decoration: underline;
}

.search-container ul {
     list-style-type: none;
}

.search-container li {
    margin-top: 10px;
    margin-bottom: 42px;
}

.search-container h2 {
    font-size: 24px;
    margin: 0;
}