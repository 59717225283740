.search-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    height: 44px;
}

.search-input {
    border: 1px solid #ccc;
    padding: 0.5em;
    font-size: 16px !important;
    width: 80%;
    border-radius: 20px 0 0 20px;
    outline: none;
    flex-grow: 1;
    height: 25px;
    margin: 0 !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

/* Media query for mobile devices */
@media (max-width: 900px) {
    .search-input {
      width: 70%; /* Adjust the width for smaller screens */
    }
  }

  /* Media query for mobile devices */
@media (max-width: 600px) {
    .search-input {
      width: 50%; /* Adjust the width for smaller screens */
    }
  }
  
    /* Media query for mobile devices */
@media (max-width: 500px) {
    .search-input {
      width: 50%; /* Adjust the width for smaller screens */
        min-width: 5rem !important;
    }

    .search-button {
        padding: 1px 1px !important;
    }
  }
  

.search-input.searching {
    border-radius: 20px 0 0 0;
}

.search-button {
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px !important;
    border-radius: 0 20px 20px 0;
    outline: none;
    background-color: #3abada;
    color: white;
    margin: 0 !important;
    height: 43px !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.search-button.searching {
    border-radius: 0 20px 0 0 !important;
}

.search-bar {
    display: flex;
    width: 80%;
    margin: 0 auto;
}

.articles-list {
    display: none;
}

.articles-list.searching {
    width: 80%;
    z-index: 99;
    border-top: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 20px 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    display: block !important;
}

.article-item {
    z-index: 99;
    padding: 8px 16px;
    margin: 5px;
    cursor: pointer;
    color: #101010;
    border-radius: 20px;
}
a {
    text-decoration: none;
}

.article-item:hover {
    background-color: #f0f0f0;
}
