.background {
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .container {
    display: fixed;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    position: relative;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 780px; /* adjust this value as needed */
    margin-bottom: 20px;
  }
  
  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
  }
  
  .contentPopup {
    display: flex;
    margin-top: 60px;
    padding-left: 20px;   
    padding-right: 20px; 
    overflow-y: scroll;
  }
  
  .inputGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px;
  }
  
  .inputGroup input[type="text"] {
    padding: 10px;
    margin: 0 10px;
    font-size: 16px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  
  button[type="quick"] {
    padding: 10px;
    margin: 20px 0;
    font-size: 16px;
    border-radius: 10px;
    background-color: #2c3e50;
    color: #fff;
    cursor: pointer;
    width: 30%;
  }
  
  button[type="quick"]:hover {
    background-color: #4e6e8d;
  }
  
  .patientInfo {
    display: flex;
    justify-content: space-between;
  }
  
  .patientInfo > div {
    flex-basis: 48%;
  }
  
  .genderSelect {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom: -.6rem;
    font-size: 16px;
    box-shadow: 0px 0px 5px 0px #888888;
  }
  
  .payerSelect {
    display: block;
    width: 100%;
    height: 400px;
    padding: 10px;
    margin-right: 20px;
    margin-left: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom: -15px;
    font-size: 16px;
    box-shadow: 0px 0px 5px 0px #888888;
  }
  
  .select-container {
    display: fixed; /* use flexbox to align items */
    justify-content: center; 
    
  }
  
  .select-container select {
    width: 80px; /* increase the width of the select element */
    padding: 5px; /* add some padding to the select element */
    font-size: 16px; /* increase the font size */
    border-radius: 15px;
  
  }
  
  .inputGroup label {
    display: block;
    margin-bottom: 5px;
    font-size: 0.8rem;
    color: #888;
  }
  
  .inputGroup input,
  .inputGroup select {
    padding: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
    margin-right: 20px;
  }
  
  .inputGroup input[type="date"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 20px;
  }
  
  .patientGridContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 60px;
    align-items: right;
    margin: -20px 0px ;
      overflow: scroll;
    }
    
    .patientGridContainer > * {
      border: 1px solid #ccc;
      padding: 10px;
    }
    
    .payerSelection {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-height: 500px;
      overflow-y: scroll;
      padding: 20px;
    }
    
    .table {
      border-collapse: separate;
      border-spacing: 0;
      margin-bottom: 20px;
      border-radius: 15px;
    }
    
    .table td,
    .table th {
      border: none;
      border-bottom: 1px solid #ccc;
  
    }
    
    .table th {
      text-align: left;
      border-bottom: 2px solid #ccc;
      min-width: 200px;
    }
    
    .table thead {
      background-color: #f2f2f2;
  
    }
    
    .table tbody tr:nth-child(even) {
      background-color: #1874bf09;
    }
    
    .patientNotFoundMessage {
      color: red;
      font-weight: bold;
      margin-top: 1rem;
      display: flex;
      justify-content: center;
  
    }
    .payerErrorMessage {
      color: red;
      font-weight: bold;
      margin-top: 1rem;
      display: flex;
      justify-content: center;
    }
    
    .SingleClaimContainer {
      height: 75%;
    }
  
    .searchButtonContainer {
      margin: 0 auto;
    }

    .claimContainer {
      height: 100rem;
      margin-bottom: 128px;
    }
    
    .status-box {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
    }
    
    .payerSelection {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      max-height: 300px;
      overflow-y: scroll;
      padding: 20px;
      border-radius: 15px;
    }
  
    .button-spinner-wrapper {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    .collapsibleSection .sectionHeader {
      display: flex;
      align-items: center;
      font-size: 26px;
      justify-content: flex-start;
      width: 100%; 
    }
    
    .collapsibleSection .sectionNumber {
      font-weight: bold;
      margin-right: 5px;
    }
  
    .CollapsibleSection_ .sectiontitle {
      text-align: left;
      cursor: pointer;
    }

    .selectors-container {
      display: flex;
      justify-content: space-between;
    }
    
    .singleclaim{

      min-height: 400px;
      max-height: 900px;
      padding-left: 20px;   
      padding-right: 20px; 
      overflow-y: scroll;
    }

    .dependentPopup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    margin-left: 20rem;
    right: 0;
    bottom: 0;
    background: rgb(255, 255, 255);
    z-index: 9999;
    padding: 20px;
    box-sizing: border-box;
}

.codesTable {
  cursor:alias;
}

.errors2Section{
  display: flex;
  flex-direction: column;

}

.error-text {
  margin: 1rem;
  display: block;
}