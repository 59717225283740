/* Add this style for the dark mode */
.dark-mode {
  background-color: #222;
  color: #fff;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.mainContent {
  margin-top: 5rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  width: 100%;
}

.authDetails {
  position: fixed;
  top: 0;
  right: 0;
}

.title {
  display: block;
  text-align: center;
  font-size: 30px;
  margin-top: 172px; /* Add 200px spacer at the top */
  margin-bottom: 0rem; /* Separate top and bottom margins */
  font-weight: 600;
}

.background {
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.logoContainer {
  display: flex;
  align-items: left;
  margin-right: 20px;
  position: relative;
}

.logo img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 200px;
}


.background {
  background-color: var(--color-background);
  color: var(--color-text);
}

.title {
  color: var(--color-text);
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}