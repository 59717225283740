

.popupContentAddPatient {
  position: fixed;
  right: 0px;
  width: 66.7%;
  height: 95.2%;
  background-color: var(--color-background);
  color: var(--color-text);
  overflow: hidden;
  margin-top: 0rem;
  z-index: 4;

}

.cancelButton {
  display: flex;
}

.provider-selection-container {
  height: 500px;
}

.add-patient-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding-top: 3rem;
  border-radius: 0;
  height: 95%;
}

.add-batch-popup {
  display: flex;
  width: 100%;
  margin-top: 3rem;
  margin-left: 2rem;
  border-radius: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add-patient-form {
  border-radius: 0px;
  justify-content: center;
  height: 100%;
  bottom: 0;
  padding-left: 10%;
  padding-right: 10%;
}

.form-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.input-group {
  flex: 1;
  margin: 0 10px;
  margin-top: 10px;
}

.input-group input {
  margin-right: .5rem;
  margin-left: .5rem;
}

.gender-buttons {
  justify-content: left;
  margin: 1rem;
}

.gender-button {
  background-color: #e0e0e0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 6px 12px;
  font-size: 14px;
  color: black;
  width: 5rem;
}

.gender-button.selected {
  background-image: linear-gradient(135deg, #206274, #3abada);
  color: #fff;
}

.address {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.payerSelection {
  display: flex;
  flex-direction: column;
  margin: 0;
}

button[type="submit"]:hover {
  background-color: #206274;
}

.addPatientButton {
  color: linear-gradient(135deg, #206274, #3abada);
  cursor: pointer;
  padding-bottom: 5rem;
  font-size: 20px;
  color: #fff;
  justify-content: center;
  display: flex;
  margin-top: 1rem;
}

.successMessage {
  justify-content: center;
  justify-items: center;
  margin-top: 10%;
}

.add-patient-intake {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0;
  height: 90%;
  padding: 1rem;

}

.input-group {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding-left: .2rem;
  justify-content: center;
  align-items: center;


}

.input-group>input,
.input-group>label {
  margin-bottom: 10px;
}

.input-group>button {
  margin-top: 10px;
}

.input-field {
  display: flex;
  flex-direction: column;
  margin: .5rem;
}

.input-group-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: .5rem;
}

.input-group-row label {
  margin-bottom: 10px;
}

.input-group-row label {
  font-size: 0.8rem;
  color: #888;
}

.input-group label {
  font-size: 0.8rem;
  color: #888;
  margin-left: .5rem;
}

.input-group textarea {
  height: 15%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  font-size: 18px;
  box-shadow: 0px 0px 5px 0px #888888;

}

.input-group-column {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
}

.input-group-column label {
  margin-bottom: 12px;
  text-align: left;
  font-size: 0.8rem;
  color: #888;
  width: 100%;
}

.input-group label {
  font-size: 0.8rem;
  color: #888;
  margin-left: .5rem;
}

.input-group textarea {
  height: 15%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  font-size: 18px;
  box-shadow: 0px 0px 5px 0px #888888;

}


.input-group-payerSelection {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;

}

.payerContainer {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.edit-group-payerSelection {
  flex-direction: column;
  display: flex;
  height: 20rem;
}

.stateInput {
  min-width: 1rem;
}

.zipInput {
  min-width: 3rem;
}

@media (max-width: 480px) {

  .input-group-row {
    display: flex;
    flex-direction: column;
  }

  .input-group textarea {
    width: 15rem;
  }

}

.editPatient {
  align-items: normal;
}


.errorTable {
  width: 100%;
  border-collapse: collapse;
  overflow-y: auto;
}

.errorTable th,
.errorTable td {
  border: 1px solid #dddddd;
  padding: 1rem;
  text-align: left;
  height: .1rem;
}

.errorTable th {
  background-color: #f2f2f2;
  height: .1rem;

}

.batchpatientContainer {
  left: 1rem;
  right: 1rem;
}

.errorsBatch {
  overflow-y: auto;
  height: 50vh;
  padding: 2rem;
}

.addpatientbuttonContainer {
  display: flex;
}

.addpatientactionButton {
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: #206274;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px #888888;
  width: 100%;
  max-width: 12rem;
  display: flex;
  align-items: left;
  margin-top: 0px;
  margin-bottom: 20px;
  margin-left: 0;
}

.addpatientactionButtonIcon {
  margin-right: 10px;
}

.addpatientbatchButton {
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: #206274;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px #888888;
  display: flex;
  align-items: left;
  margin-top: 0px;
  margin-bottom: 20px;
}

.patientsActionButtonText{
  padding-top: .2rem;
}

.centerHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.preview-image-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
}

#previewImage {
  width: 40%; 
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  #previewImage {
      width: 25%;
  }
}

.eligibilityCheckbox {
  margin-bottom: 1rem;
}