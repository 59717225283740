/* Website.css */

body {
  overflow-x: hidden;
  max-width: 100%;
  margin: 0px;
}


header {
    background-color: white;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    width: 100%;
    height: 3rem;
}

.content-container {
  display: flex;
  height:100%;
  margin-top: 3rem;
}

.landing-page {
  display: flex;
  flex-direction: column;
}



.nav-links {
    display: flex;
    list-style: none;
    margin-top: .42rem;
    margin-bottom: .5rem;
    padding: 0;
    gap:1rem;
    margin-right: 1rem;
}

.nav-links li a {
    color: #f7f7f7;
    display: flex;
    padding: 0.5rem;
    text-decoration: none;
    font-weight: 100;
}

.hero {
    padding: 4rem 1rem;
    text-align: center;
    align-items: center;
}

.hero h1 {
    color: #3abada;
    font-size: 50px;
    margin-bottom: 2rem;
}
.hero p {
    color: #ffffffc0;
    font-size: 20px;

}

.boxes {
    display: flex;
    justify-content: space-around;
    padding: 2rem 1rem;
    text-align: center;
}

.box {
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    padding: 1.5rem;
    width: 45%;
}

.signup-btn,
.login-btn {
  background-color: #206274;
  border: none;
  border-radius: 25px;
  color: white;
  padding: 1rem;
  margin: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);

}

.signup-btn:hover,
.login-btn:hover {
  background-image: linear-gradient(135deg, #206274, #3abada);

}



.center-login-btn {
    display: absolute;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #020202;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0px 0px 0px 0px #000000;
    margin-left: 0rem;
  }
  
  .center-login-btn:hover {
    background-color: #000000;
    color: #ffffff;

  }
  
  
  .logoHome {
    align-items: center;
    height: 50px;
    width: auto;
    margin-left: .5rem;
    margin-top: .25rem;
  }

  .privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .privacy-policy-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container h2 {
    font-size: 1.8rem;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  
  .privacy-policy-container p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container ul {
    list-style: disc;
    margin-left: 20px;
  }
  
  .privacy-policy-container a {
    color: #3abada;
    text-decoration: underline;
  }
  
  @media (max-width: 600px) {

    .headerRMobileDisplay {
      display: none;
    }
    .privacy-policy-container h1 {
      font-size: 2rem;
    }
    
    .privacy-policy-container h2 {
      font-size: 1.5rem;
    }
    
    .privacy-policy-container p {
      font-size: 1rem;
    }
  }
  .container {
    margin: 0 auto;
    max-width: 800px;
    padding: 40px;
  }
  
  h1 {
    text-align: center;
    color: #0c0c0c;
    font-size: 56px;
    font-weight: 600;
    margin-top: 0rem;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
      font-size: 28px;
      opacity: 90%;
      font-weight: 100;
  }

  h4 {
    font-size: 18px;
    opacity: 90%;
    font-weight: 100;
}

  p {
    font-size: 18px;
  }
  
  
  ul {
    margin-bottom: 16px;
    margin-left: 5%;
  }
  
  strong {
    font-weight: bold;
  }
  
  /* support-page.css */

  .support-container {
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 10vh;
  }

  .support-container-image {
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 10vh;
  }
  
  .support-container h2 {
    text-align: center;
    color: #194d85de;
  }

  .support-container h4 {
    text-align: center;
  }
  
  .support-container label {
    display: block;
    margin-bottom: 0.5em;
  }
  
  .support-container input,
  .support-container select,
  .support-container textarea {
    width: 70%;
    padding: 0.5em;
    margin-bottom: 1.5em;
    font-size: 1em;
    border: 1px solid #ccc;
  }
  
  .support-container button {
    background-color: #3abada;
    color: white;
    border: none;
    padding: 0.8em 1.5em;
    font-size: 1em;
    cursor: pointer;
  }
  
  .support-container button:hover {
    background-color: #1e62d0;
  }
  
  .block {
    background-color: white;
    padding: 2rem 1rem;
    text-align: center;
    height: auto;
}

    
    .pricing-block {
        padding: 50px 0;
        background-color: #f8f8f8; /* You can change the background color to your preference */
    }



    .videoSection {
      position: relative;
    }
    
    .fullBleedVideo {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    .desktopVideo {
      display: block;
    }
    
    .mobileVideo {
      display: none;
    }

        /* Media query for screens less than or equal to 768px */
    @media (max-width: 768px) {
      .desktopVideo {
        display: none;
      }

      .mobileVideo {
        display: block;
      }
    }

    .laptopVideo {
      width: 65%;
      height: 100%;
      object-fit: cover;
    }


    .videoHeaderClaims {
      margin-bottom: 1rem;
    }
    
    .heroText {
      position: absolute;
      top: 20%;
      left: 10%;
     height:30rem; 
     width: 80vh; 
     text-align: left;
      color: #ffffff;
    }
    
    .heroText h1 {
      font-weight: lighter;
      margin-bottom: 1rem;
    }
    
    .heroText h3 {
      font-weight: lighter;
    }
    

    .prooflogo {
      width: auto;
      height: 3rem; /* Adjust the height as needed */
      margin-right: 4rem; /* Adjust the spacing between logos */
      margin-top: 1rem;
    }
    
    .containerHex {
        background-color: white;
        padding: 2rem 1rem;
        text-align: center;
        height: auto;
      }


      .title {
        display: block;
        text-align: center;
        font-size: 25px;
        margin-bottom: 60px; /* Separate top and bottom margins */
        font-weight: 600;
      }

      .blue-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-image: linear-gradient(135deg, #206274, #3abada);
        border-radius: 20px;
        text-align: left;
        margin-left: 5%;
        margin-right: 5%;
    }

    .left-content {
      color: white;
      padding-right: 0%;
      padding-left: 2%;
      padding-top: 0%;
      width: 50%;
    }

    .right-content {
      padding-right: 2%;
      padding-left: 2%;
      padding-bottom: 1rem;
      padding-top: 0%;
    }
    
    .Home_cloud__bvdCQ {
      height: 30rem;
    }
      
      .block-tall h2 {
        font-size: 36px;
        margin-bottom: 20px;
      }
      
      .block-tall p {
        font-size: 24px;
        margin-bottom: 40px;
      }
      
      .join-now-button {
        background-color: white;
        color: #206274;
        border: none;
        padding: 100px;
        font-size: 20px;
        border-radius: 10px;
        cursor: pointer;
      }
      
      
      
      .block-content {
        text-align: center;
        margin-bottom: 70px;
      }
      
      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10%;
        margin-right: 10%;
      }
      
      .icon-container > div {
        text-align: center;
        margin: 0 1rem;
      }
      
      .icon-container > div > h3 {
        margin-top: 24px;
      }
      
      .icon-container > div > p {
        margin-top: 10px;
        font-size: 18px;
        color: #666;
      }
      
      .icon-container > div > svg {
        width: 50px;
        height: 50px;
        color: #3abada;
      }

      
  
  .pricing-table p {
    text-align: center;
    font-size: 14px;
    margin-bottom: 10px;
    opacity: 0.5;
  }
  
  .pricing-table h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  
  .pricing-table th,
  .pricing-table td {
    border: 0px solid rgba(204, 204, 204, 0.5);
    padding: 15px;
  }
  
  .thread {
    background-color: #007bff00;
  }
  
  
  .pricing-table {
    font-family: Arial, sans-serif;
    padding: 0rem;
    position: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    width: 100%;
    padding: 0;
  }
  
  
  .pricing-table p {
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
    opacity: 0.5;
  }
  
  .pricing-table h1 {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .pricing-table table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    border-spacing: 0;
    border: none 
  }
  
  .pricing-table thead th {
    font-weight: bold;
    
  }
  
  .pricing-table button {
    color: #3abada;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 50px;
    margin-top: 20px;
    box-shadow: none;
    position: relative;
  }
  
  .pricing-table button:hover {
    background-color: #0056b3;
    color: white;
    text-decoration: none;
  }
  
  .pricing-table thread td:first-child,
  .pricing-table tbody td:first-child {
    font-weight: bold;
    text-align: right;
  }
  
  .pricing-table thead th p {
    font-size: 18px;
    font-weight: 500;
    display: inline;
    margin-bottom: 10px;
  }
  
  /* Infill the button in column 3 */
  .pricing-table thead th:nth-child(3) button {
    background-color: #206274;
    color: white;
  }
  
  .pricing-table thead th:nth-child(3) button:hover {
    background-color: #ffffff;
    color: #206274;
  }
  
  .pricing-table .table-container {
    overflow-x: hidden ;
    position: relative;
    width: 100%;
  }
  
  .pricing-table .price {
    font-size: 50px;
    font-weight: 250;
    margin-top: 10px;
    justify-content: left;
  }
  
  .pricing-table .month {
    font-size: 18px;
    opacity: 0.5;
    margin-left: 5px;
  }
  
  .pricing-table .price-container {
    margin-top: 20px;
  }

  .footer {
    background-color: #206274;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .logo-container {
    margin-right: 1rem;
  }
  
  .logoFooter {
    height: 5rem;
    align-items: center;
  }

  .HIPAA {
    height: 100px;
    margin-top: 100px;
  }
  
  .footer-links {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: 100px;
    color: #f8f8f8;
  }
  
  .footer-links li {
    margin-right: 1rem;
}

  
  .all-rights-reserved {
    margin-top: 1rem;
    text-align: center;
    flex-basis: 100%;
  }
  
  .join-now-button {
    display: inline-block;
    padding: 15px;
    border-radius: 10px;
    text-decoration: none; 
  }
  
 .block .blue-block .right-content .try-gpt-box .ai-screenshot {
    max-width: 100%;
    height: auto;
    display: block;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Adds a shadow effect */
    border-radius: 5px; /* Rounds the corners of your image */
    transition: transform .2s; /* Animation for hover effect */
}

    .block .blue-block .right-content .try-gpt-box .ai-screenshot:hover {
        transform: scale(1.2); /* Slightly enlarges the image when hovered */
    }


 

  .centered-text {
    text-align: center;
  }

  .bigger-image {
    width: 70%;  
    height: auto;
    margin: 0 auto;  /* Add auto margins to center the image block */
  }
  
  .patientsImage {
    display: flex;
    width: 100%;
    justify-content: center;  /* Add this line to center the image */
  }
  
  .differentBlock {
    background: linear-gradient(135deg, #206274, #3abada);
    text-align: left;
    padding: 200px;
    margin: 200px;
    border-radius: 20px;
}

.AMA{
  height: 5rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}



.payerSelectorContainer {
  width: 100%;
  height: 100%;
}

.payer-content {
  text-align: left;
  width: 100%;
}

.full-bleed {
  width: 100%; 
  object-fit: cover;
  border-radius: 5px;
}

.leftTele {
  width: 50%;
  margin-left: 10%;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
  .leftTele {
    width: 90%;
    margin-left: 5%;
  }
}


.teleBlueBox{
  position: relative;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(135deg, #206274, #3abada);
  border-radius: 10px;
  margin: 0;
  padding: .5rem;
  margin-left: 20%;
}


.tele-text-content {
  display: flex;
  margin-right: 10%;
  text-align: left;
}
.teleText{
  margin-left: 15%;
}

.payerText{
  margin-left: 20%;
  margin-right: 10%;
}



.full-bleed-codes {
  width: 30%; 
  height: auto;
}

.codes-content {
  text-align: left;
  right: 0;
}

.codesText{
  margin-right: 20%;
  margin-left: 10%;
}

.codeSearch {
  margin-left: 10%;
  justify-content: center;
  height: 20rem;
  padding-left: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  
}

.codeHeader{
  text-align: left;
}


.logo-container {
  display: flex;
  align-items: center;
}

.zoomlogo {
  width: 8rem;
  height: auto;
  margin-right: 2rem;
}
.vertical-line {
  width: 1px;
  height: 5rem;
  background-color: black;
  margin-right: 1rem;
  margin-left: 1rem;
}

.clinics {
  width: 25%;
  margin-right: 10rem;
    display: flex;
  object-fit: cover;
}

.clinicsBlock {
  background-color: white;
  text-align: center;
  height: auto;
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 3rem;
}

.clinicText {
  margin-right: 2rem;
  text-align: left;
}



.FAQ-container {
  margin-left: 5%;  
  margin-right: 5%;
  padding: 3rex;
  border-radius: 10px;
}

.FAQ-container h1 {
  text-align: left;
  margin-bottom: 20px;
}

.FAQ-container h2 {
  cursor: pointer;
  margin: 10px;
  margin-bottom: 28px;
  text-align: left;
  font-weight: 300;

}

.FAQ-container p {
  margin: 10px 0;
  margin-left: 1.5rem;
  padding: 10px;
  border-radius: 5px;
  text-align: left;
  font-size: 18px;
}

.Features-container {
  margin-left:5%;
  margin-right:5%;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;

}

.Features-container h1 {
  text-align: left;
  margin-bottom: 20px;
  border-radius: 1rem;
}

.Features-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.Features-item {
  padding: 10px;
  border-radius: 10px;  
  text-align: left;
  
}

.websiteICD10 {
  display: flex;
  margin-left: 0rem;
}





@media (max-width: 768px) {
  header {
      max-height: 3rem;
      width: 100%;
  }

  .heroText h1 {
      font-size: 32px;
      margin-bottom: 0.5rem;
  }
  
  .heroText h3 {
      font-size: 18px;
      margin-top: 0rem;
  }

  .heroText {
    width: 70%;
    top: 32%;
    left: 5%;
  }

  .prooflogo {
    height: 1.5rem;
    margin-right: 2rem;
  }

  .box {
      width: 100%;
  }
  

  .footer-links li {
      margin-bottom: 0.5rem;
  }
  
  .logoFooter {
      margin-left: 2rem;
  }

  .HIPAA {
      margin-top: 50px;
  }

  .bigger-image {
    width: 100%;
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .content-container {
    display: block;
  }

  .codes-content {
    width: auto;
  }

  .full-bleed-codes {
    width: 100%;
  }
  .payerText {
    margin-left: 1rem;
  }

  .pricing-table .table-container {
    overflow-x: scroll;
  }

  .codeSearch{
    padding: 0%;
  }

  .blue-block {
    display: block;
  }

  .try-gpt-box {
    margin-top: 1rem;
  }

  .clinicsBlock {
    display: block;
  }
  
  .clinics {
    margin-left: 1rem;
    width: 80%;
  }
 


}

@media (max-width: 480px) {
  header {
  width: 100%;  
  max-height: 6rem;
}

 .nav-links {
    gap: .25rem;
 }
  .nav-links li a {
      padding: 0.25rem;
      margin-right: 0rem;
      font-size: 14px;
      padding-bottom: .5rem;
      padding-top: .5rem;
    }

  .hero h1 {
      font-size: 25px;
  }
  
  .hero p {
      font-size: 14px;
  }

  .box {
      width: 100%;
  }

  .privacy-policy-container h1 {
      font-size: 1.5rem;
  }
  
  .privacy-policy-container h2 {
      font-size: 1.2rem;
  }
  
  .privacy-policy-container p {
      font-size: 0.8rem;
  }
  
  .footer-links {
      margin-right: 0px;
  }
  
  .logoFooter {
      margin-left: 2rem;
      height: 4rem;
  }
  
  .HIPAA {
      margin-top: 50px;
}

  .bigger-image {
    width: 100%;
  }



  .payerSelectorContainer {
    margin-left: 0;
  }

  .zoomlogo {
    width: 7rem;
    margin-right: -2rem;
  }
  .icon-container {
    display: contents;
  }

  h1 {
    font-size: 40px;

  }

  .AMA {
    height: 6rem;
    margin-bottom: 0rem;
  }

  .logo-container {
    display: block;
    margin-bottom: 0rem;
  }

  .vertical-line {
    width: 0;
    height: 1rem;
  }

  .blue-block {
    display: block;
    margin-left: .5%;
    margin-right: .5%;
  }

  .try-gpt-box {
    margin-top: 1rem;
  }

  .left-content {
    width: auto;
    padding-top: .5rem;
    margin-left: .25rem;
    margin-RIGHT: .25rem;
  }

  .right-content {
    padding-left: .5%;
    padding-right: .5%;
  }

  .hero {
    padding: 2rem 1rem;
    text-align: center;
}

.clinicsBlock {
  display: block;
}

.clinics {

  width: 80%;
}

.clinicText {
  margin-left: .2rem;
  margin-right: .2rem;
  margin-bottom: 2rem;
}

.codesText {
  margin-left: 1rem;
  margin-right: 1rem;

}

.teleBlueBox {
  margin-bottom: 5%;
  width: 100%;
  margin-left: 0%;
}

.payerContainer {
  width: auto;
}
.create-account-button {
  position: block;
  margin-right: 0rem;
}
.leftTele {
  width: 70%;
}

.codeSearch {
  height: 25rem;
}

}


.footer {
  background-color: #206274;
  color: #ffffff;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.logo-container {
  margin-bottom: 2rem;
}


.footer-links {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.footer-link {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.create-account-button {
  border: 2px solid white;
  color: white;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  top: 10px;
  right: 10px;
  margin-bottom: 2rem;
  border-radius: .5rem;
}

.create-account-button:hover {
  background: linear-gradient(135deg, #206274, #3abada);

}

.footer {
  position: relative;
}


.account-button-container {
  position: absolute;
  bottom: 0;
  right: 0;
}


.footer-link:hover {
  color: #3abada;
}

.all-rights-reserved {
  font-size: 0.8rem;
}

.footer .social-links {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  padding: 10px;
  gap: 1.5rem;

}

.footer .social-links a {
  color: white;
  font-size: 24px;
  transition: color 0.5s;
}

.footer .social-links a:hover {
  color: #ddd;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0,0,0,0.4); /* Black with opacity */
}

.modal-content {
  background-color: #fefefe;
  margin-left: 35%;
  margin-top: 5rem;
  margin-right: 35%;
position: fixed;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  overflow-y: hidden;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  flex: 1 0 auto;
  margin-bottom: 50px;  /* adjust as needed */
}


/* General styles for the select dropdown */
select {
  font-size: 16px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  appearance: none;
  -webkit-appearance: none;  /* Override default select box in webkit browsers */
  -moz-appearance: none;     /* Override default select box in Mozilla Firefox */
  cursor: pointer;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  width: 50%;               /* Full width of the container */

}


/* Styles for the textarea */
#message {
  width: 100%;  /* Full width of the container */
  font-size: 16px;
  border: 1px solid #ccc;  /* Light gray border */
  border-radius: 5px;      /* Slight roundness */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  /* Subtle box shadow for depth */
  transition: box-shadow 0.3s ease, border-color 0.3s ease;  /* Smooth transition for hover and focus effects */
  resize: vertical;        /* Allows user to resize textarea vertically, not horizontally */
  background-color: #ffffff;
  line-height: 1.5;        /* Spacing between lines */
}

/* Hover effect for textarea */
#message:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Slightly more pronounced shadow on hover */
}

/* Focus effect for textarea */
#message:focus {
  box-shadow: 0 0 0 3px rgba(100, 150, 255, 0.5);  /* Blue shadow for accessibility */
  outline: none;  /* Remove default outline in some browsers */
  border-color: #3abada;  /* Matching blue border color */
}

/* If you want a specific style for when the textarea is in a "required" state and empty (and invalid) */
#message:required:invalid {
  border-color: #e74c3c;  /* Red border */
  box-shadow: none;       /* Remove shadow */
}


.section-header-techspecs {
  float: left;
  width: 22%;
}

.tech-specs-column-row{
  width: 75%;
}

.tech-specs-list{
  margin-left: 0;
  list-style: none;
  font-size: 17px;
  margin-top: 0;

}

.section-tech-specs {
  padding-top: 60px;
  padding-bottom: 60px;
  border-top: 1px solid #d2d2d7;
  display: flex;
  align-items: flex-start;
  
}

.section-content {
  margin-left: auto;
  margin-right: auto;
  width: 980px;
  margin-top: 3rem;
}

.section-headline {
  font-size: 24px;
  line-height: 1.16;
  font-weight: 600;
  letter-spacing: .009em;
  margin-top: 0;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.tech-specs-list li:not(:first-child) {
  margin-top: 1rem;
}

.tech-specs-grid {
  grid-template-columns: 43% 43%;
  width: 100%;
  margin-left: 0px;
  float: none;
  padding: 0 24px;
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  max-width: 1380px;
}

.tech-specs-grid-item {
  background-color: unset;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
}

.tech-specs-grid-3wide {
  grid-template-columns: 29.3% 29.3% 29.3%;
  width: 100%;
  margin-left: 0px;
  float: none;
  padding: 0 24px;
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  max-width: 1380px;
}

.landing-page {
  font-family: 'Arial', sans-serif;
}

.hero-section {
  height: 90vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  margin-top: -50vh;
}

.devices-section, .screenshots-section {
  padding: 4rem 2rem;
}

.device-content, .screenshots-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.device-images, .screenshot-images {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.device, .screenshot {
  margin: 1rem;
  text-align: center;
}

.device-image, .screenshot-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin-bottom: 1rem;
}

.edge-bleed-section {
  padding: 6rem 0;
  color: white;
  text-align: center;
  padding-left: 20vw;
padding-right: 20vw;
}

.edge-bleed-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 2rem;
}

.screenshots-section {
  background-color: white;
  padding: 4rem 2rem;
}
