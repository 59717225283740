

.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.container {
  margin-left: 64px;
  margin-right: 64px;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 200px);
  position: relative;
}

.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  min-height: 100vh;
}

.content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-left: 200px;
  flex-grow: 1;
}

.folderControlsContainer {
  position: fixed;
  left: 0;
  margin-top: 2.8rem;
  height: 95%;
  width: 13%;
  background-color: rgb(243, 243, 245);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 0;
}

.folderList {
  list-style-type: none;
  padding-left: 0px;
  padding-right: 5%;
  margin-top: 10;
}

.folder {
  margin-bottom: 10px;
}

.folderIcon {
  margin-right: .25rem;
  margin-left: .25rem;
}

.titleBox {
  display: flex;
  justify-content: center;
  margin-left: 20%;
  margin-right: 20%;

}

.firstRow {
  display: fixed;
  align-items: center;
  width: 100%;
}

.secondRow {
  display: fixed;
  align-items: center;
  width: 100%;
}

.patientViewBar {
  position: absolute;
  left: 13%;
  width: 20%;
  height: 95%;
  overflow-y: auto;
  background-color: white;
  margin-top: 45px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}



.patientWindow {
  position: absolute;
  right: 0px;
  top: 0;
  width: 67%;
  height: 95%;
  margin-top: 2.8rem;
  background-color: white;
  overflow: auto;
}

.clinicWindow {
  position: fixed;
  right: 0px;
  top: 0;
  width: 67%;
  height: 95%;
  margin-top: 2.8rem;
  background-color: white;
  overflow: auto;
}

.actionContainer {
  position: relative;
  overflow-y: scroll;
  height: 95%;
  bottom: 0;
}

.ClaimContainer {
  position: fixed;
  right: 0px;
  width: 66.7%;
  height: 95%;
  top: 0;
  background-color: var(--color-background);
  color: var(--color-text);
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 2.8rem;
  z-index: 4;
}

.EligibilityContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}


.collectionsContainer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 95%;
  overflow: hidden;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
  margin-top: 20px;

}


.file-input {
  margin-top: 20px;
}

.file-input input {
  margin-bottom: 10px;
}

.progressBar {
  width: 100%;
  margin-top: 250px;
}

.outerbar {
  width: 100%;
  background-color: #ddd;
  border-radius: 10px;
}

.innerbar {
  background-color: #4CAF50;
  height: 20px;
  text-align: center;
  color: white;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.section {
  width: 80%;
  margin: 5px;
  align-items: center;
}



.requestsContainer {
  max-height: 500px;
  overflow: auto;
}

.totalRequestsContainer {
  display: fixed;
  justify-content: bottom;
  align-items: center;
  height: 50px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  margin-top: 20px;
  padding: 10px;
  font-weight: bold;
  font-size: 18px;
}

button {
  padding: 10px;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px #888888;
  margin: 10px;
}

.table {
  width: 100%;
  max-width: 800px;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #e0e0e0;
  padding: 8px;
  text-align: left;
}

.table thead {
  background-color: #f2f2f2;
}

.table tbody tr:nth-child(even) {
  background-color: #f8f8f8;
}

.payerSelection label {
  margin-right: 10px;
}

.formContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filesTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 700px;
  overflow-y: scroll;
  padding: 20px;
}

.filesTable th,
.filesTable td {
  border: 1px solid #e0e0e0;
  padding: 8px;
  text-align: left;
  font-size: 14px;
  /* Adjust font size */
}

.filesTable thead {
  background-color: #f2f2f2;
}

.filesTable tbody tr:nth-child(even) {
  background-color: #f8f8f8;
}



.fileName {
  margin-left: 1rem;
}

.fileItem {
  display: grid;
  align-items: center;
}

.deleteBtn {
  padding: 7px;
  border-radius: 10px;
  background-color: #f30c0c;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px #888888;
  width: 100%;
  margin-bottom: 20px;
  /* Add margin-bottom to create space between buttons */

}

.downloadBtn {
  padding: 7px;
  border-radius: 5px;
  background-image: linear-gradient(135deg, #206274, #3abada);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px #888888;
  margin-top: 20px;
  /* Add margin-top to create space between buttons */

}



.columns {
  display: flex;
}

.scrollingTable {
  height: 100%;
  overflow-x: hidden;
  margin-top: 12px;
  overflow-y: hidden;
}

table {
  overflow-y: auto;
  text-align: center;
}

table td {
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid #ddd;
  align-items: center;
}

.error-message {
  color: #ff4d4d;
  /* Red color for error text */
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  border: 1px solid #ff4d4d;
  /* Red border for the container */
  border-radius: 10px;
  padding: 10px;
  background-color: #ffe6e6;
  /* Light red background color */
  max-width: 400px;
  text-align: center;
}

.actions {
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
}

.patientFiles {
  padding: 20px;
  padding-top: .99rem;
}

.patientDataContainer {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: -3rem;
}

.action-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}


.folderSelectContainer {
  padding: 20px;

}

.patientDataContainer .patientDataTitle .initialsCircleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.patientDataContainer .patientDataTitle .initialsCircle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-image: linear-gradient(135deg, #206274, #3abada);
  color: white;
  font-size: 50px;
  text-align: center;
  line-height: 100px;
  margin-bottom: 1rem;
  display: block;
  margin-left: 0;
  margin-right: 0;
}

.patientDataContainer .patientDataTitle .name {
  margin-top: .5rem;
}

.dobandgender {
  text-align: center;
  margin-top: -1rem;
}

.actions .action-button-container button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 150px;
  background-image: linear-gradient(135deg, #206274, #3abada);
}

.actions .action-button-container button.selected {
  background-image: none;
  background-color: #3abada; /* Change as needed */
  color: white; /* Change as needed */
}



.icon-container {
  margin-bottom: 10px;
}

.singleClaimContainer {
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 3rem;
  margin-top: 40px;
}

.search-input-patients {
  width: calc(100% - 20px) !important;
  min-width: 100px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 10px;
}


.selected-patient {
  background-color: rgba(0, 0, 0, 0.1);
}

.selectedFolder {
  background-color: rgba(0, 0, 0, 0.1);
  padding: .5rem;
  border-radius: 10px;
}

/* Style the checkbox container */
.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkbox {
  position: absolute;
  top: -1.2rem;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkbox {
  background-color: #206274;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark {
  position: relative;
  top: 5px;
  left: 9px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkbox .checkmark {
  display: block;
}

.trashButton {
  background: transparent;
  box-shadow: none;
  color: #f30c0c;
  margin-top: 0rem;
}

.downloadButton {
  background: transparent;
  box-shadow: none;
  color: #206274;
  margin-top: 0rem;
}

.confirmModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 4px;
  outline: none;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

}

.selectedSortButton {
  background-color: #206274; /* Darker shade for selected state */
}

.checkoutPatients {
  padding-left: 1rem;   
  padding-right: 2rem; 
  padding-top: 3rem;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 95%;
}

.invalid-row {
  background-color: #ffb3b3;  /* for example, a light red to indicate error */
}

.inline-error-message {
  margin: 0; /* Remove default margins */
  padding: 5px 10px; /* Add some padding for visual space */
  display: inline-flex; /* Make sure the error messages act like inline elements */
}

.selectAllButton {
  border: none;
  align-items: center;
  cursor: pointer;
  
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.payerSearchTable {
  height: 30rem;
  overflow-y: scroll;
}

.payerMatchSearch {
  padding: 1rem;
}

.payerSearchTable li {
  list-style: none;
  padding: 5px;
  cursor: pointer;
}

table tr {
  border-bottom: 1px solid #3d2929; /* Faint grey line */
}

table tr:last-child {
  border-bottom: none; /* Remove the border for the last row */
}

.payerNameForm {
  padding-top: 1rem;
}

.payerMatchContent {
  max-height: 40rem;
}

.payerMatchedTable {
  max-height: 20rem;
  overflow-y: scroll;
}

.matchCloseButton {
  width: 24px;
  height: 24px;
  background-color: #ccc;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: -1rem;
}
  .quickSearchHelper {
    margin-top: 5rem;
  }


  .scrollToTopButton {
    position: fixed;
    top: 4rem;
    left: 20%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-image: linear-gradient(135deg, #206274, #3abada);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    visibility: hidden; /* Hide the button initially */
    opacity: 0;  /* Start with 0 opacity */
    transition: opacity 0.2s ease-out, visibility 0.2s ease-out; /* Add transition for visibility */
}

.scrollToTopButton.visible {
  visibility: visible; /* Show the button when .visible is added */
  opacity: 1;  /* Full opacity when the .visible class is added */
}


.patientControls {
  display: flex;
}

.multiControls {
  display: flex;

}

.relativePositioned {
  position: relative;
  text-align: left;
}

.unbilledIndicator {
  color: green;
  position: absolute;
  right: 0;
  top: 50%; 
  transform: translateY(-50%); /* This is to vertically center the $ symbol */
}


.EncountersContainer {
  padding: 2rem;
  margin-top: 2rem;

}

.encounterCardContent {
  padding: .5rem;
}

.largerIcon svg-inline--fa {
  height: 44px; /* Adjust the size as needed */
}

.modalContent {
  padding: 1rem;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timerContainer {
  display: flex;
  align-items: center;
  color: #3abada;
  padding-left: .5rem;
  height: 0rem;
}

.timerText {
  margin-left: 10px;
  padding: 0; /* Set padding to 0 */
}



.notificationCard {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 1000;
}

.notificationHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.notificationHeader h4 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.notificationClose {
  font-size: 18px;
  color: #aaa;
  cursor: pointer;
  margin-left: 8px;
  transition: color 0.2s;
}

.notificationClose:hover {
  color: #f00;
}

.notificationMessage {
  font-size: 14px;
  margin: 10px 0;
}

.notificationButton {
  align-self: flex-end;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.notificationButton:hover {
  background-color: #0056b3;
}
