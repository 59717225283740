
  .svg {
    position: fixed;
    top: 40%;
    left: 40%;
    transform: translate(-50%, -50%);
    font-size: 24px;
  }
  .svg-inline--fa {
    height: 50px;
  }
  
  .hexagonButton h2 {
    font-size: 20px;
    margin: 20;
    bottom: 0px;
    font-weight: 600;
  }
  
  .hexagonButton p {
    font-size: 14px;
    margin: 0;
  }
  
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .hexagonButton {
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-image: linear-gradient(135deg, #206274, #3abada);
    color: #fdfdff;
    font-weight: 700;
    cursor: pointer;
    font-size: 24px;
    font-family: 'Hevelica', 'SofiaPro', 'Avenir', sans-serif;
    height: 250px;
    width: 250px;
    position: relative;
    transition: all 0.3s ease-in-out;
    overflow: visible;
    z-index: 99;
  }

      /* Adjustments for screens up to 480px wide (typical mobile screen) */
    @media (max-width: 480px) {
      .hexagonButton {
          font-size: 10px;
          height: 7rem;
          width: 7rem;
          margin: -0.8rem .1rem;
      }
    }

    /* Adjustments for screens between 481px and 768px wide (typical tablet screen) */
    @media (min-width: 481px) and (max-width: 768px) {
      .hexagonButton {
          font-size: 10px;
          height: 10rem;
          width: 10rem;
          margin: -1.1rem .2rem;
      }
    
    }

    /* Adjustments for screens over 768px wide (typical desktop/laptop screen) */
    @media (min-width: 769px) {
      .hexagonButton {
          font-size: 24px;
          height: 250px;
          width: 250px;
      }
    }

  .hexagonShadowWrapper {
    display: inline-block;
    position: relative;
    filter: drop-shadow(-5px 6px 3px rgba(0, 49, 111, 0.45));
  }ƒ

  .error {
    width: 50%;
  }
  
  .hexagonButton:hover {
    background-image: linear-gradient(150deg, #3abada, #206274);
  }
  