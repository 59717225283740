

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  
  h1 {
    text-align: center;
    color: #206274;
    ;
  }
  
  .buttonai {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    box-shadow: none;
    background-color: white;
    margin-top: 10px;
}

.textLabel {
  margin-bottom: -.5rem;
}
  .chat {
    position: fixed;
    width: 100%;
    right: 0;
  }

  .aibackground {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-size: cover;
      min-height: 100vh;
      width: 100%;
      background-color:rgb(243, 243, 245);
  }

  .sidebarContainer {
    position: fixed;
    left: 0;
    margin-top: 3rem;
    margin-left: .5rem;
    height: 100%;
    width: 19%;
    background-color: rgb(243, 243, 245);
  }
  
  .bg-load {
    background: linear-gradient(to right, #e6e6e6, #ababab);
    background-size: 200% 200%;
    animation: gradient 2s ease infinite;
  }
    
  @keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
  }
  
  
  
  
  .documentContainer {
    display: flex;
    justify-content: left;
    align-items: center;

  }
  
  .appTitleContainer{
    display: flex;
    align-items: center;
  }
  
  .appTitle {
    margin-left: 5px;
    letter-spacing: 2px;
    align-items: center;
  
  }
  
  

  
  .rightNav {
    display: flex;
    align-items: center;
    margin-top: .0rem;
  
  }
  



  .documentLabel {
    margin-left: 1rem;
    width: 100%;
  }
  
  .nameBold {
    font-weight: bold;
  }
  
  .initialsCircle {
    background-image: linear-gradient(135deg,#206274,#3abada);
    border-radius: 100%;
    color: #fff;
    font-size: 22px;
    padding: .75rem;
    line-height: inherit;
    margin-right: 2rem;
    margin-top: -.5rem;
    display: flex;
  }

  .usercircleContainer {
    margin-left: 0rem;
  }

  .sources {
    display: flex;
    align-items: center;
    padding-left: 20rem;
    padding-right: 15rem;
  }
  
  .sidebar {
    background-color: white;
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    height: 76.7vh;
  }

  .uploads-list {
    height: 60%;
    overflow-y: auto;
  }
  
  .mainContainer {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
  
  .subContainer {
    display: flex;
  }
  
  .subContainer > :first-child {
    order: 0; /* Default value */
  }
  
  @media (max-width: 600px) {
    .mainContainer {
      gap: 2rem;
    }
  
    .subContainer {
      flex-direction: column;
    }
  
    .subContainer > :first-child {
      order: 1; /* This will push the first child to the end */
      width: 80%;
      margin-left: 10%;
      margin-right: 10%;
      margin-top: 10%;
    }
  
    .scroll-to-bottom-btn {
      bottom: 40%;
      position: relative;
    }

    .sources {
      display: flex;
      align-items: center;
      padding-left: 25%;
      padding-right: 0%;
      width: 50%;
    }

    .initialsCircle {
      margin-right: .60rem;
    }

    .usercircleContainer {
      padding-left: .7rem;
      padding-right: .65rem;
    }

  }
  
  .scroll-to-bottom-btn {
    position: relative;
    margin-right: 0%;
    margin-left: 90%;
    background-color: white;
    box-shadow: none;      
    color: #000;

  }

  
  
  .disclaimer {
    font-weight: 100;
    color: #8b8b8b;
    margin-top: -1rem;
  }
  
  .disclaimerSpecial  {
    font-weight: 100;
    color: #ffffff;
    margin-top: -1rem;
  }
  