
.topbar {
  background-color: #eeeeee80;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.75rem;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 10px rgba(0,0,0,.1);
}

.logo {
  position: relative;
  left: 0px;
  align-items: center;
  height: 50px;
  width: auto;
  margin-top: 5px;

}

.appTitleContainer{
  display: flex;
  align-items: center;
}

.appTitle {
  margin-left: 5px;
  letter-spacing: 2px;
  align-items: center;

}


.leftNav {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.rightNav {
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 5px;

}

.nav {
  display: flex;
  align-items: center;
}

.navItem {
  margin: 0 15px;
}

.navLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}

.navLink:hover {
  color: #000;
}

.navLinkText {
  margin-top: 5px;
}

.dropdownButton {
  background-color: #eeeeee00;
  border: none;
  cursor: pointer;
  outline: none;
  color: #00000096;
  font-size: 5px;
  margin-top: 1rem;
  box-shadow: none;
}



.dropdown {
  position: absolute;
  top: 100%;
  right: 4px;
  margin-right: 10px;
  min-width: 200px;
  background-color: #206274;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0,0,0,.3);
  z-index: 999;
}

.dropdown .dropdownLink:first-child:hover {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dropdown .dropdownLink:last-child:hover {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dropdownLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}

.dropdownLink:hover {
  background-image: linear-gradient(135deg, #206274, #3abada);
}

.dropdownLink + .dropdownLink {
  border-top: 1px solid #ccc;
}


.dropdownLinkText {
  margin-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dropdownLink:first-child:hover {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dropdownLink:last-child:hover {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}


.authText {
  background-color: #3abada;
  padding: 10px;
  border-radius: 10px 10px 0 0; 
}

.dropdownsignout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  padding-top: 5px;
}

.dropdownsignout:hover {
  background-image: linear-gradient(135deg, #206274, #3abada);
  
}


.svg-inline--fa {
  height: 20px;
  justify-content: center;
}

.nameBold {
  font-weight: bold;
}
