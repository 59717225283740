.background {
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.AUTH-container {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -300px 0 0 -350px;
  padding: 20px;
  border-radius: 20px;
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.0);
  box-shadow: 0px 0px 25px 0px #888888a4;
  height: auto;
  width: 700px;
  background-color: #ffffff;
}

input {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 0px 5px 0px #888888;
  -webkit-appearance: none;

}


error {
  width: 50%;
}



button:active {
  transform: translateY(1px);
}

@media (max-width: 500px) {
  .sign-up-container {
    margin: 20px auto;
    max-width: 300px;
  }
}

button[type="submit"] {
  padding: 10px;
  margin: 20px 0;
  font-size: 16px;
  border-radius: 5px;
  background-color: #206274;
  color: #fff;
  cursor: pointer;
}



.logo {
  width: 200px;
}

.signup-link,
.forgot-password-link {
  color: #3abada;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;

}
.signInBtn {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}
/* Custom checkbox style */
.custom-checkbox {
  position: relative;
  min-width: 1rem;
  width:2rem;
  height: 2rem;
  cursor: pointer;
  margin-bottom: 0rem;
  margin-right: 1.5rem;
  /* Override -webkit-appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Apply gradient effect when pressed */
  background: linear-gradient(to bottom, #e6e6e6 0%, #f9f9f9 100%);
  border-radius: 4px;
  border: 1px solid #ccc;
}

.custom-checkbox:checked {
  background: linear-gradient(135deg, #206274, #3abada);
}

/* Add a checkmark when the checkbox is checked */
.custom-checkbox:checked::before {
  content: "\2713";
  position: absolute;
  top: 3px;
  left: 5px;
  font-size: 12px;
  color: #ffffff;
}

/* Hide the default checkbox */
.custom-checkbox::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

/* Add a custom focus style */
.custom-checkbox:focus {
  outline: none;
  box-shadow: 0 0 0 2px #6c9eeb; /* Adjust color as needed */
}



.popupCard {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  width:  40%;
  max-width: 700px;
  max-height: 900px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 400;
}

.payment-form-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.plan-details-list {
  padding: 10px;
  margin-bottom: 5px;
}

.payment-form__detail-item {
  font-size: 14px;
  margin-bottom: 8px;
}


.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.subheading {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 20px;
}

.cancel {
  font-size: 14px;
  color: #888888;
}

.input-field {
  margin-bottom: 20px;
}

.signup-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.powered-by-stripe {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.stripe-logo {
  width: 50px;
  height: auto;
  margin-right: 5px;
}



.pricing-table h1 {
  text-align: center;
  margin-bottom: 20px;
}


.pricing-table th,
.pricing-table td {
  border: 0px solid rgba(204, 204, 204, 0.5);
  padding: 15px;
}

.thread {
  background-color: #007bff00;
}



.pricing-table p {
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
  color: #ffffff;

}

.pricing-table h1 {
  text-align: center;
  margin-bottom: 40px;
  color: #ffffff;
}

.pricing-table table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  border-spacing: 0;
  border: none 
}

.pricing-table thead th {
  font-weight: bold;
  
}

.pricing-table button {
  background-color: transparent;
  border: 2px solid #1873bf;
  color: #1873bf;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 50px;
  margin-top: 20px;
  box-shadow: none;
}

.pricing-table button:hover {
  background-image: linear-gradient(135deg, #206274, #3abada);
  color: white;
  text-decoration: none;
}

.pricing-table thread td:first-child,
.pricing-table tbody td:first-child {
  font-weight: bold;
  text-align: right;
}

.pricing-table thead th p {
  font-size: 14px;
  font-weight: 500;
  display: inline;
  margin-bottom: 10px;
}

/* Infill the button in column 3 */
.pricing-table thead th:nth-child(3) button {
  background-color: #1873bf;
  color: white;
}

.pricing-table thead th:nth-child(3) button:hover {
  background-color: #ffffff;
  color: #1873bf;
}

.pricing-table .table-container {
  overflow: hidden;
  position: relative;
}

.pricing-table .price {
  font-size: 50px;
  font-weight: 250;
  margin-top: 10px;
  justify-content: left;
}

.pricing-table .month {
  font-size: 14px;
  opacity: 0.5;
  margin-left: 5px;
}

.pricing-table .price-container {
  margin-top: 1rem;
}

.AUTH-container-PRICING {
  display: flex;
  padding: 4%;
  border-radius: 20px;
  background-image: linear-gradient(135deg, #206274, #3abada);
  box-shadow: 0px 0px 5px 0px #888888;
  height: 100%;
  margin-left: 5%;
  margin-right: 5%;
}

.pricing-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.plan-container {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  width: 100%;
}

.plan-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 10px 0 rgba(0,0,0,0.6);
  margin: .5rem;
  padding: 2rem;
  width: calc(100%/2 - 1rem);
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.plan-description {
  height: 7rem; 
  overflow: hidden; 
}

.plan-card:hover {
  transform: scale(1.02);
}

.plan-card h2 {
  margin-bottom: 1rem;
  color: #ffffff;
  font-size: 3rem;
  font-weight: 500;
}

.plan-card p {
  color: #777;
  font-size: 1rem;
  margin-bottom: 2rem;
}

.plan-card ul {
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
}

.plan-card ul li {
  margin-bottom: 0.5rem;
  color: #555;
  font-size: 0.9rem;
}

.price-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.price {
  font-size: 2rem;
  color: #333;
}

.month {
  font-size: 1.2rem;
  color: #777;
}




.pricingContainerAuth{
  width: 90%;
  height: 80%;
  top:0;
  margin-top: 2%;
}

.StripeElement {
  min-width: 200px;
  width: 35%;  padding: 15px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.StripeElement input {
    min-width: 200px;
    width: 35%;
}

.h2 {
  font-size: 18px;
  opacity: 0.5;
  margin-bottom: 30px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hexagon {
  width: 30px;
  height: 17px;
  position: relative;
  animation: spin 2s linear infinite;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hexagon:before,
.hexagon:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-style: solid;
}

.hexagon:before {
  border-bottom: 8px solid #3498db;
  top: -8px;
}

.hexagon:after {
  border-top: 8px solid #3498db;
  bottom: -8px;
}
.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  z-index: 1002;
}
.logo {
  position: absolute;
  left: auto;
  height: 50px;
  width: auto;
  margin-top: auto;
}

.logoWrapper {
  position: relative;
  width: 100%;
  margin-left: 1rem;
  margin-top: -.4rem;
}

@media screen and (max-width: 992px) {
  .plan-container {
    flex-direction: column;
  }

  .plan-card {
    width: 100%;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 768px) {

  .AUTH-container {
    top: 20%;
    margin: 0;
    width: 20rem;
    left: 3%;
    right: 3%;
  }
  .AUTH-container-PRICING {
    padding: 2%;
    margin-left: .2rem;
    margin-right: .2rem;
  }


  
  .pricing-table {
    padding: 1rem;
  }

  .plan-card {
    padding: 0rem;
    width: 95%;
  }

  .price-container {
    flex-direction: column;
  }

  .month {
    margin-top: 0.5rem;
  }

  .StripeElement, .StripeElement input {
    width: 100%;
  }
}