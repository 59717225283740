.background {
  background-color: var(--color-background);
  color: var(--color-text);
}

.title {
  color: var(--color-text);
}

.Claims-inner-container {
  position: fixed;
  bottom: 0;
  right: 0px;
  width: 79%;
  height: 86%;
  background-color: white;
  overflow: hidden;
  margin-top: 3.5rem;
  flex: 1;
  padding: 1rem;
  z-index: 11;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
}

.DashboardContainer {
  position: fixed;
  right: 0px;
  top: 0;
  width: 79%;
  height: 95%;
  overflow-y: auto;
  margin-top: 3rem;
  z-index: -1;
}

.checkout {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.batchListContainer {
  position: fixed;
  left: 20%;
  height: 96%;
  top: 0;
  width: 20%;
  min-height: 200px;
  background-color: #f1f1f1;
  z-index: 3;
  margin-top: 2.75rem;
  overflow-y: auto;
}

.claimsListContainer {
  position: fixed;
  left: 0;
  height: 96%;
  top: 0;
  width: 20%;
  min-height: 200px;
  background-color: #f1f1f1;
  margin-top: 2.75rem;
  overflow-y: auto;
}


.file-browser-container {
  position: fixed;
  left: 15%;
  width: 24%;
  height: 95%;
  overflow-y: auto;
  background-color: white;
  margin-top: 45px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-left: 0px;
}

.folder-list,
.file-list {
  overflow-y: auto;
  padding: 10px;
  list-style: none;
}

.folder-list li,
.file-list li {
  cursor: pointer;
  padding: 8px;
  margin-bottom: 4px;
  border-radius: 4px;
  right: 0;
  width: auto;
}

.cw-pane-container {
  display: flex;
  height: 100vh;
}

.split-view {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.tri-page {
  display: flex;
  height: 100%;
  flex-direction: row;
}



#title {
  display: flex;
  color: #194d85de;
  left: auto;
  text-align: left;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkoutformContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.form label {
  font-size: 1.2rem;
}

.form input[type="text"],
.form input[type="file"] {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 10px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.form button[type="button"],
.form button[type="submit"] {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 10px;
  border: none;
  background-color: #0077c2;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.form button[type="button"]:hover,
.form button[type="submit"]:hover {
  background-color: #005fa3;
}

.outerbar {
  width: 100%;
  max-width: 500px;
  height: 20px;
  border-radius: 10px;
  background-color: #e6e6e6;
  overflow: hidden;
}

.innerbar {
  height: 100%;
  background-color: #0077c2;
  transition: width 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: white;
  font-weight: bold;
}

.error-message {
  color: red;
  font-weight: bold;
}

input[type="file"] {
  display: none;
}

#batchName {
  margin-top: 20px;
}

#templateButton {
  margin-top: 18px;
  left: 42%;
  padding: 10px;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px #888888;
  min-width: 200px;
}

input {
  margin-left: .5rem;
  margin-right: .5rem;
  padding: 10px;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 0px 5px 0px #888888;
  min-width: 15rem;
  -webkit-appearance: none;
}


.button-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  display: block;
}

.button-spacing {
  width: 20px;
  height: 20px;
}

.error-table-container {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

table {
  border-collapse: collapse;
}

.selected-diagnosis-code {
  background-color: rgba(0, 0, 0, 0.1);
}

.selected-codes-container {
  display: flex;
  flex-wrap: wrap;
}

.selected-code {
  margin-right: 10px;
}

.batch-summary-container {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.diagnosis-selector,
.select-payer {
  width: 48%;
}

.nextButtonContainer {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.collapsibleSection .sectionHeader {
  display: flex;
  align-items: center;
  font-size: 26px;
  justify-content: flex-start;
  width: 100%;
}

.collapsibleSection .sectionNumber {
  font-weight: bold;
  margin-right: 5px;
}

.CollapsibleSection_ .sectiontitle {
  text-align: left;
  cursor: pointer;

}

.batch-popup-container {
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.batch-popup {
  position: fixed;
  right: 0px;
  width: 80%;
  height: 95%;
  background-color: white;
  overflow: hidden;
  text-align: left;
  z-index: 3;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 3rem;
}

.batch-popup-title {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
}

.batch-popup-text {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.batch-popup-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  background-color: #206274;
  border: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}

.batch-popup-button:hover {
  background-color: #1874bf69;
}

.batch-popup-button:focus {
  outline: none;
}

@keyframes slideIn {
  from {
    top: -50px;
    opacity: 0;
  }

  to {
    /* transform: translateY(0); */
    top: 0;
    opacity: 1;
    z-index: 1;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.batch-placeholder {
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, #e0e0e0 4%, #fff 25%, #e0e0e0 36%);
  background-size: 1000px 100%;
  height: 90px;
  width: 90%;
  margin: 6px 12px;
  border-radius: 8px;
}

.batch-wrapper {
  margin: 6px 12px;
  padding-top: .5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-bottom: .2rem;
  background-color: #fff;
  border-radius: 8px;

}

.batch-wrapper:hover,
.batch-wrapper.batch-selected {
  /* Your hover styles go here, for example: */
  cursor: pointer;
  background-color: #ccc;
}

.batch-wrapper h3 {
  margin: 0;
  font-size: 18px;
  padding-bottom: 5px;
}

.batch-wrapper .batch-meta {
  margin-left: 37px;
  color: #999;
  font-size: 14px;
}

.batch-wrapper .batchlist {
  margin: 10px 0;
  padding-left: 0;
}

.batch-wrapper .batch {
  margin-bottom: 5px;
  list-style: none;
}

.batch-header {
  display: flex;
  align-items: center;
  gap: 10px
}

.batch-header-trading {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 2rem;
}

.batch-header-claimsList {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.batch-info-claimsList {
  display: flex;
  align-items: center;
}

.batch-icon {
  margin-right: 8px;
  /* Adjust spacing as needed */
}

.medical-icon {
  margin-right: 5px;
}

.instructions {
  display: none;
}

#templateButton:focus+.instructions,
#templateButton:active+.instructions {
  display: block;
}

.popupCloseButton {
  width: 24px;
  height: 24px;
  background-color: #ccc;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.selected-billing-code {
  background-color: #f0f0f0;
  cursor: pointer;
}


/* Dropdown container */
.dropdown-container {
  position: relative;
  display: inline-block;
}

/* Dropdown label */
.dropdown-label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

/* Style the select element */
.dropdown-select {
  font-size: 14px;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23777' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 8px;
  min-width: 200px;
  cursor: pointer;
  outline: none;
}

/* Add a hover effect to the dropdown */
.dropdown-select:hover {
  border-color: #888;
}

.add-billing-code-popup {
  display: flex;
  width: 70%;
  max-height: 30rem;
  overflow-y: scroll;
  padding-left: 1rem;
  padding-right: 1rem;
  background-image: linear-gradient(135deg, #206274, #3abada);
  border-radius: 20px;
  color: white;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}

.MainActionButton.selected {
  background-color: #3abada;
}


th,
td {
  padding: 10px;
  border: 0px;
}

.payertableHeader thread {
  color: #206274;
}

li span {
  align-items: center;
  text-align: left;
}

li span button {
  margin-left: 0;
}

.provider-selection-container tbody tr:hover {
  cursor: pointer;
}

.provider-selection-container tbody tr:hover {
  cursor: pointer;
}

/* Style for the table rows */
.table tr {
  cursor: pointer;
}

/* Style for the table rows */
tbody tr {
  cursor: pointer;
}

/* Style for the table rows on hover */
.billingCodesContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  /* spacing between the cards */
  padding: 20px;
  /* spacing around the container */
}

/* Responsive breakpoints */
@media (min-width: 600px) {
  .billingCodesContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .billingCodesContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .billingCodesContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}

.results-container {
  max-height: 15rem;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 0.5rem;
}

.claimToolsButtons {
  display: flex;
  min-height: 3rem;

}

.MainActionButton {
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px #888888;
  display: flex;
  align-items: center;
}

.popupContentWithList {
  margin-left: 25%;
}


.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submit-button {
  width: 200px;
  height: 40px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.cancelButton {
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  margin-right: 0px;
  text-align: center;
  box-sizing: border-box;
  position: absolute;
  top: 10px;
  left: 10px;
}

.ChartTitle {
  display: flex;
  align-items: center;
  justify-items: center;
}

.Charts {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}

.SingleChart {
  width: 50%;
}

.frequencyTables {
  display: flex;
  justify-content: space-around;
  align-items: top;
  padding: 20px;
}

.Summary {
  display: flex;
  justify-content: space-around;
  align-items: top;
  padding: 20px;
}


/* Example media query for smaller screens */
@media (max-width: 768px) {
  .Charts {
    flex-direction: column;
  }
}

.FrequencyTableContainer {
  display: block;
}

.status-summary-item {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.summary-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.summary-item p {
  margin: 0;
  font-size: 1rem;
}

.summary-item h3 {
  margin: 0;
  font-size: 1.5rem;
}

.filesCloseButtonClaimsSingleView {
  position: relative;
  top: -20px;
  left: -10px;
  width: 24px;
  height: 24px;
  background-color: #ccc;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.claimsTableContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  align-items: center;
  justify-items: center;
  width: 100%;
  margin-left: 1rem;
}

.claimsDataTable {
  width: 100%;
  text-align: center;
}

.claimsDataHeader,
.claimsDataCell {
  padding: 8px;
}

td {
  text-align: center;
}

.claimsMenus {
  align-items: center;
  padding: 2rem;
}

.scrollButtonContainer {
  display: flex;
  align-items: center;
  padding-left: 10rem;
}



.scrollToTopButtonClaims {
  position: fixed;
  top: 4rem;
  left: 27%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: linear-gradient(135deg, #206274, #3abada);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  visibility: hidden;
  /* Hide the button initially */
  opacity: 0;
  /* Start with 0 opacity */
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  /* Add transition for visibility */
}

.scrollToTopButtonClaims.visible {
  visibility: visible;
  /* Show the button when .visible is added */
  opacity: 1;
  /* Full opacity when the .visible class is added */
}

.claims-search-bar {
  justify-content: left;
  margin-left: -1rem;
  z-index: 9;
  min-width: 40%;
}

.claims-search-bar-content {
  display: flex;
  width: 90%;
  margin-left: 1rem;
  margin: 0 auto;
}


.search-results {
  width: auto;
  display: flex;
  display: grid;
  background-color: #f1f1f1;
  color: black;
  margin-top: .5rem;
  z-index: 8;
  border-radius: 10px;
}

.search-results-container {
  height: 80%;
  z-index: 8;
}

.dropdownClaims {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  min-width: 200px;
  max-height: 0; /* Initially set max-height to 0 for scroll-down effect */
  overflow: hidden; /* Hide overflow to achieve scroll-down effect */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
  background-color: #5050508a;
  z-index: 999;
  opacity: 0;
  animation: fadeInAndSlide 1s ease forwards;
}

@keyframes fadeInAndSlide {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 100%;
  }
}

.dropdownClaims > * {
  padding: 10px;
  transition: background-color 0.3s ease;
}



.dropbtn {
  display: flex;
  flex-direction: row;
}

.toolsBar {
  display: flex;
}

.StatusandEligibility {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* This creates two columns of equal width */
  gap: 20px;
  /* Optional: adds some space between the columns */
  /* Add any additional styling as needed */
}

.iconClaimsList {
  width: 3rem;
}