.newClinic {
    margin-top: 4rem;
    margin-left: 2rem;
}

.clinicsList {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 2rem;
    overflow-x: auto;
}

.url-container {
    display: flex;
    align-items: center;
    padding: 2px;
}

#clinicNameURL {
    border: none; 
    outline: none;
    flex: 1; 
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(55, 55, 55, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .modalBox {
    background-color: #f1f1f1;
    padding-top: 0px;
    padding: 0 8px 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 80%;
    z-index: 1000;
    min-height: 450px;
    max-height: 61.8033vh;
    overflow-y: auto; 
    overflow-x: hidden;
    border-radius: 16px;
    max-width: 80vw;
    min-width: 650px ;
  }

  .addClinicButton {
    padding: 10px;
    border: none;
    width: 100%;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 1px 5px 0px #888888;
    min-width: 8rem;
    display: flex;
    align-items: left;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
}

.step-content {
    padding: 1rem;
}

.stepperContainerClinics {
    padding-top: 2rem;
}

.selectedSlot {
    border: 3px solid black;
    padding: 5px;
    display: inline-block;
    border-radius: 10px;
}

.continueButton {
    font-weight: 800;
    margin-left: 10px;
    cursor: pointer;
}



.clinicAddModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    outline: none;
    width: 80%;
    max-width: 900px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    max-height: 90vh;
    overflow-y: auto;
  }

  .IPA-form {
    width: 62.8vw;
    margin-left: auto;
    margin-right: auto;
    background-color: #ffffff;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
    border-radius: 20px;
    box-shadow: 0px 0px 25px 0px #888888a4;
  }
  
  .IPA-background {
    background-color: #f5f5f5;
    height: 100%;
    width: 100%;
    min-height: 100vh;
  }
  
  
  .IPA-container {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #f5f5f5;
  
  }
  