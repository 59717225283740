.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(148, 232, 243);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

:root {
  --color-background: #ffffff;
  --color-text: #000000;
  /* Add other variables as needed */
}

.dark-mode {
  --color-background: #1c1c1c;
  --color-text: #f1f1f1;
  /* Override other variables for dark mode */
}


/******** Global Styles *********/


/** Margin & Padding **/
.ml-0 {
  margin-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.m-3 {
  margin: 8px;
}

.mt-3 {
  margin-top: 8px;
}

.mb-3 {
  margin-bottom: 8px;
}

.mt-5 {
  padding: 64px !important;
}

.mr-3 {
  margin-right: 8px;
}

.py-4 {
  padding-top: 18px !important;
  padding-bottom: 0px !important;
}

.p-4 {
  padding: 32px !important;
}

.p-5 {
  padding: 64px !important;
}


/** Contatiners **/
.flex-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-center h5 {
  margin: 4px;
  text-align: center;
}

.display-flex {
  display: flex;
}

.display-block {
  display: block;
}

.display-grid {
  display: grid;
}

/** Buttons **/

.primary {
  background: none;
  border: none;
  cursor: pointer;
  color: #f1f1f1;
  background-color: #206274;
  margin-left: 10px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary:hover {
  background-color: #3abada;
}

.secondary {
  background: none;
  border: none;
  cursor: pointer;
  color: #206274;
  background-color: #f1f1f1;
  margin-left: 10px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary:hover {
  background-color: #3abada;
  color: #f1f1f1;
}

.link {
  background: none;
  border: none;
  cursor: pointer;
  color: #206274;
  margin-left: 10px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link:hover {
  color: #3abada;
}

.delete {
  background: none;
  border: none;
  cursor: pointer;
  color: rgba(255, 0, 0, 0.3);
  margin-left: 10px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete:hover {
  color: rgba(255, 0, 0, 0.7);
}

.danger {
  background: none;
  border: none;
  cursor: pointer;
  background-color: rgba(255, 0, 0, 0.7);
  color: #fff;
  margin-left: 10px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.danger:hover {
  background-color: rgba(255, 0, 0, 0.3);
}

.deleteText {
  color: rgba(255, 0, 0, 0.7);
  cursor: pointer;
  font-size: 20px;
  font-weight: 100px;
  margin: 10px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.filesCloseButton {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 24px;
  height: 24px;
  background-color: #ccc;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.filesCloseButton:hover {
  background-color: rgb(0, 49, 111);
  color: #f1f1f1;
  /* background-color: #999; */
}

/** Annomations **/
.slide-in {
  animation: slideIn .5s ease forwards;
  position: relative;
  z-index: 0;
}

/** Containers **/
.cancelButton {
  /* position: inherit; */
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  margin-right: 0px;
  text-align: center;
  box-sizing: border-box;
  top: 10px;
  left: 10px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  /* adjust as necessary for spacing between cards */
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.grid-container-lg {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  /* adjust as necessary for spacing between cards */
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/* Tablet view: Three column layout */
@media (max-width: 1200px) {

  /* 1200px is a common breakpoint for tablets. Adjust as needed. */
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
/* 
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  } */
}

/* Mobile view: Single column layout */
@media (max-width: 768px) {

  /* 768px is a common breakpoint for mobile devices. Adjust as needed. */
  .grid-container {
    grid-template-columns: 1fr;
  }
}


/** Text **/

.error-text {
  color: rgba(255, 0, 0, 0.7);
}

/** Cards **/

.primary-card {
  background-color: #206274;
  padding: 8px;
  margin: 8px;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.primary-card:hover {
  background-color: #3abada;
}

.white-card {
  background-color: rgb(243, 243, 245);
  box-shadow: 0px 1px 5px 0px #d3d3d3;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  transition: transform 0.5s ease-in-out;
}

.white-card:hover {
  background-color: #206274;
  color: rgb(243, 243, 245);
  transform: scale(1.01);

}

.grey-card {
  background-color: rgb(243, 243, 245);
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.blue-card {
  background-color: #206274;
  color: rgb(243, 243, 245);
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.Card {
  background-color: #206274;
  /* This is a shade of blue */
  padding-top: 0rem;
  padding-bottom: 0rem;
  padding-left: .5rem;
  padding-right: .5rem;
  margin: 10px 0;
  border-radius: 10px;
  color: white;
  /* Makes the text inside the card white */
  cursor: pointer;
  /* Indicates to users that the card is clickable */
  transition: background-color 0.3s;
  /* Smooth transition for hover effect */
}

/* .blue-card:hover {
  background-color: #206274;
  color: #010101;
} */

.card {
  transition: transform 0.2s ease-in-out;
}

.card-selected {
  transform: scale(1.05);
}

.add-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-top: 3rem;
  border-radius: 0;
  height: 95%;
}

.over-add-popup {
  position: absolute;
  top: -3rem;
  z-index: 99999999999;
  background: white;
  height: 100%;
}

.add-form {
  border-radius: 0px;
  justify-content: center;
  padding: 3rem;
  width: 100%;
  height: 100%;
  bottom: 0;
}

.input-field {
  display: flex;
  flex-direction: column;
  margin: .5rem;
}

h1 {
  text-align: center;
  color: #0c0c0c;
  font-size: 56px;
  font-weight: 600;
  margin-top: 0rem;
  margin-bottom: -1rem;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
  opacity: 90%;
  font-weight: 100;
  margin-top: .25rem;
  margin-bottom: .25rem;
}

h4 {
  font-size: 18px;
  opacity: 90%;
  font-weight: 100;
  margin-top: 4px;
  margin-bottom: 4px;
}

p {
  font-size: 18px;
}

/* Custom checkbox style */
.custom-checkbox {
  position: relative;
  min-width: 1rem;
  width: 1.75rem;
  height: 1.75rem;
  cursor: pointer;
  margin-bottom: -.5rem;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 0rem;
  /* Override -webkit-appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Apply gradient effect when pressed */
  background: linear-gradient(to bottom, #e6e6e6 0%, #f9f9f9 100%);
  border-radius: 4px;
  border: 1px solid #ccc;
}

.custom-checkbox:checked {
  background: linear-gradient(135deg, #206274, #3abada);
}

/* Add a checkmark when the checkbox is checked */
.custom-checkbox:checked::before {
  content: "\2713";
  position: absolute;
  top: 3px;
  left: 5px;
  font-size: 12px;
  color: #ffffff;
}

/* Hide the default checkbox */
.custom-checkbox::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

/* Add a custom focus style */
.custom-checkbox:focus {
  outline: none;
  box-shadow: 0 0 0 2px #6c9eeb;
  /* Adjust color as needed */
}

.popupContainer {
  position: fixed;
  top: 44px;
  right: 0;
  height: calc(100% - 44px);
  width: 66%;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: white;  
  z-index: 4;
  overflow-x: hidden;
  overflow-y: scroll;
}

.popupContent {
  background-color: white;
  z-index: 4;
  overflow-y: auto;
  overflow-x: hidden;
  /* width: 100%; */ 
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
}

.topBar {
  margin-bottom: 45px;
  padding-top: 1rem;
  margin-left: 0rem;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the start of the container */
  gap: 5px; /* Adjust the space between items */
}

.editText {
  color: #206274;
  cursor: pointer;
  font-size: 20px;
}

.downloadText {
  color: #206274;
  cursor: pointer;
  font-size: 20px;
  margin-left: 1rem;
}

table {
  width: 100%;
}

.selected {
  background-color: #3abada;
}

.stepsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.dropbox {
  width: 100%;
  max-width: 500px;
  height: 200px;
  border-radius: 10px;
  border: 2px dashed #206274;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #206274;
  font-weight: bold;
  padding: 1rem;
  flex-direction: column;
}

.dropbox:hover {
  background-color: #f2f2f2;
}

.dropbox.dragover {
  background-color: #e6e6e6;
}

.sign-up-legal-disclaimer {
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
}

/* Style the signature pad container */
.signature-pad {
  margin-top: 20px;
  text-align: center;
  padding: 20px;
  background-color: #e4e5e6;
  border-radius: 4px;
}

/* Style the signature canvas */
.sigCanvas {
  border: 2px dashed #000;
  border-radius: 4px;
  height: 200px;
  margin-bottom: 10px;
  width: 100%; /* Make the canvas responsive */
  max-width: 500px; /* Set a maximum width for larger screens */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .sigCanvas {
    width: 90%; /* Adjust the width for smaller screens */
  }
}

/* Style the clear button */
.signature-pad button {
  padding: 10px 20px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
}

.signature-pad button:hover {
  background-color: #c82333;
}


.provider-search {
  position: relative;
  width: 100%;
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.search-button {
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-results {
  list-style-type: none;
  padding: 0;
  margin: 0.5rem 0;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 4px;
}

.search-result-item {
  padding: 1rem;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.2s;
}

.search-result-item:hover {
  background-color: #e9ecef;
}

.provider-name {
  font-weight: bold;
  font-size: 1.1rem;
  color: #333;
}

.provider-details {
  font-size: 0.9rem;
  color: #666;
  margin-top: 0.25rem;
}

.search-result-item:last-child {
  border-bottom: none;
}

.provider-card {
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f9fa;
  margin-top: 1rem;
}

.change-selection-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.change-selection-button:hover {
  background-color: #c82333;
}

.payerContainer {
  display: fixed;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-image: linear-gradient(135deg, #206274, #3abada);
  border-radius: 20px;
  color: white;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  max-height: 20rem;
  text-align: center;
  margin: auto;
  padding: 20px;
  background: linear-gradient(to right, #206274, #3abada);
}

.device-selection {
  margin: 2rem 0;
}

.device-options {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.device-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s, background-color 0.3s;
}

.device-option.selected {
  border-color: #007bff;
  background-color: #e6f2ff;
}

.device-logo {
  width: 100px;
  height: auto;
  margin-bottom: 0.5rem;
}

.device-option input[type="radio"] {
  margin-bottom: 0.5rem;
}
