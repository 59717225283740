.chart-container {
    margin-top: 5rem;
    width: 75%;
}

.monitoringPatientsList {
    height: 40%;
    margin-top: 5%;
    width: 70%;
}

.dashboardMonitoring {
    margin-top: 5rem;
}

.custom-tooltip {
    background-color: white;
    padding: 10px;
    border: 1px solid #ccc;
}

.tooltip {
    position: absolute;
    text-align: center;
    width: 120px;
    padding: 2px;
    font: 12px sans-serif;
    background: lightsteelblue;
    border: 0;
    border-radius: 8px;
    pointer-events: none;
    opacity: 0;
}
