.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  padding: 2rem;
  margin-top: 80px;
  padding: 20px;
  border-radius: 20px;
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.0);
  box-shadow: 0px 0px 5px 0px #888888;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.leftColumn,
.rightColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 2rem;
}

.section {
  margin-bottom: 2rem;
}

.section h2 {
  margin-bottom: 1rem;
}

.bigName {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 2rem;
}

button {
  padding: 0.5rem 1rem;
  color: white;
  border: none;
  cursor: pointer;
}


.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.popup-content {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
}

.apiButton {
  position: fixed;  /* Fixed positioning */
  bottom: 20px;     /* 20px from the bottom of the viewport */
  right: 20px;      /* 20px from the right of the viewport */
  z-index: 1000;
}