.FormEditorContainer {
  display: flex;
  width: 100%;
  min-height: 500px;
}

.PaletteContainer {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  background-color: rgb(243, 243, 245);
  box-shadow: 0px 1px 5px 0px #d3d3d3;
  border-radius: 8px;
  height: calc(100% - 200px);
  min-height: 300px;
  position: fixed;
  right: 25px;
  top: 90px;
  width: 180px;
}

.PaletteDroppable {
  height: calc(100% - 55px);
  overflow: scroll;
}

.FormCanvasContainer {
  height: calc(100vh - 80px);
  margin-top: 10px;
  margin-right: 10px;
  width: 80.9016994%;
  width: calc(100% - 240px);
}

@media screen and (min-width: 1026px) {
  .FormCanvasContainer {
    width: calc(100% - 200px);
  }
}

@media screen and (min-width: 1440px) {
  .FormCanvasContainer {
    width: calc(100% - 180px);
  }
}

@media screen and (min-width: 1820px) {
  .FormCanvasContainer {
    width: 100%;
  }
}

.PaletteFormElementCard {
  margin: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  border-radius: 10px;
  text-align: center;
}

.DragItem {
  /* padding: 10px; */

  background-color: rgb(243, 243, 245);
  box-shadow: 0px 1px 5px 0px #d3d3d3;
  border-radius: 8px;

  margin: 0 0 20px 0;
  /* display: grid; */
  /* grid-gap: 20px; */
  /* flex-direction: column; */

  display: grid;
  grid-template-columns: calc(100% - 60px) 60px; /* Adjust percentages as needed */
  /* grid-gap: 10px; */
}

.MainContainer {
  margin-top: 27px;
  margin-bottom: auto;
  /* height: 100%; */
}

.SideContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 0;
}

.TrashButton {
  background-color: rgba(255, 0, 0, 0.7);
}

.ExpandableButton {
  /* background-color: rgba(0, 0, 0, 0.1); */
}

.TrashButton:hover {
  background-color: rgba(255, 0, 0, 0.7);
  transform: scale(1.1);
}

.ExpandableButton:hover {
  transform: scale(1.1);
}

.ExpandableArea {
  position: absolute;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0s ease;
}

.ExpandableArea.open {
  position: relative;
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 1.8s ease;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 25px;
}

.Preview {
  margin-left: 30px;
  margin-right: 30px;
}

.FormElementsContainer {
  padding-bottom: 32px;
  display: grid;
  flex-wrap: wrap;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 260px;
  padding: 1px;
  padding-bottom: 16px;
}

.FormElementsContainer > * {
  margin-right: 10px;
  margin-bottom: 10px;
}

.SaveButton {
  width: 160px;
  position: absolute;
  bottom: 0;
}

.PaletteFormElementCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.iconContainer {
  margin-left: 10px; /* Adjust as needed */
}

.RequiredMark {
  color: rgba(255, 0, 0, 0.7);
  font-size: 16px;
  font-weight: bolder;
}

.headerForm {
  font-weight: bolder;
  margin-left: 8px;
  margin-bottom: 2px !important;
}

hr {
  border-top: 1px solid black;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 0;
}

textarea {
  border: none;
  font-family: inherit;
  color: inherit;
  resize: none;
  width: calc(100% - 10px) !important;
  /* height: 100px !important; */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 18px;
  margin-right: 10px;
  margin-bottom: 10px;
}


input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  background: #00316f; /* Use the blue color from the site */
  border-radius: 50%;
  cursor: pointer;
}

/* For Firefox */
input[type="range"]::-moz-range-thumb {
  width: 30px;
  height: 30px;
  background: #00316f; /* Use the blue color from the site */
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"] {
  height: 10px;
  border-radius: 50px;
}

.OptionInput {
  width: calc(100% - 95px);
}

.ChoiceOption{
  display: flex;
  margin: 16px;
}

.TitleInput {
  border: none;
  box-shadow: none;
  font-size: 28px;
  opacity: 90%;
  font-weight: 100;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  width: 240px;
}


.FormCanvasDroppable {
  position: relative;
  border: 2px dashed #ccc;
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 256px;
  min-height: calc(100vh - 300px);
}

.FormCanvasDroppable.dragOver {
  border-color: #0147df;
}

.emptyCanvas {
  height: 200px;
}

.emptyCanvasMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #999; 
  font-size: 24px;
  text-align: center;
}

/* .FormCanvasDroppable {

} */

.FormRow {
  display: block;
}

.FormElement {
  width: 33vw;
  margin-left: auto;
  margin-right: auto;
}


.h-250px {
  height: 250px;
}

.w-100px {
  width: 100px;
}