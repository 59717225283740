.single-article-container {
    margin-top: 50px;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
    min-height: 90vh;
}

.article-title {
    margin-top: 20px;
    font-size: 30px;
    margin-bottom: 20px;
    color: #161617;
}

.article-content {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
}

.article-content a {
    color: #3498db;
    text-decoration: none;
}

.article-content a:hover {
    text-decoration: underline;
}

.article-content img {
    max-width: 100%;
    height: auto;
    max-height: 30rem;
    justify-content: center;
}
