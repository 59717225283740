/* styles.css */

.encounterCard {
    background-color: #00316f;  /* This is a shade of blue */
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: .5rem;
    padding-right: .5rem;
    margin: 10px 0;
    border-radius: 10px;
    color: white; /* Makes the text inside the card white */
    cursor: pointer; /* Indicates to users that the card is clickable */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.encounterCard:hover {
    background-color: #0147DF;  /* Darkens the blue on hover */
}

.expandedDetails {
    padding-top: 20px;
}

.notBilled {
    background-color: green;
}


/* styles.css */

.serviceAndDateContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.encounterTemplatesCards{
    max-width: 15rem;
}

.templatesMenu {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* This will create columns that are at least 200px wide, but will stretch to fill available space */
    gap: 20px; /* Spacing between grid items */
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.templateDetails {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
}

.templateDetails p {
    margin: .5rem; /* Remove default margins on <p> elements */
  }